import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import ContactFormNew from "../components/ContactFormNew"
import CTA from "../components/CTA"
import WipeScreen from "../components/WipeScreen"
import SEO from "../components/SEO"

import { Container, Row, Col } from "reactstrap"

import ArrowTop from "../images/icons/arrow-top.svg"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string
    }
  }
}

const Contact: React.FC<PageProps<DataProps>> = () => {
  return (
    <>
      <SEO title={"Let's talk"} slug={"contact"} />
      <Row className="white-container case-study">
        <Container className="mt-5 pt-5">
          <Row className="mt-5 pt-5 no-gutters">
            <Col sm="8" className="super-margin">
              <h1>
                Let's talk about your project. Fill in the form or send an email
                if you prefer.
              </h1>
            </Col>
          </Row>
          <Row className="no-gutters mt-5 mb-5">
            <Col md="5">
              <h3>Send e-mail</h3>
            </Col>
            <Col md="7" className="long">
              <a href="mailto:hello@projectscope.eu">
                <u className="large-p">hello@projectscope.eu</u>
              </a>
            </Col>
            <Col md="5">
              <h3>Schedule a chat</h3>
            </Col>
            <Col md="7" className="long">
              <a
                href="https://calendar.app.google/C7kSm9catLxLyJXv5"
                target="_blank"
              >
                <u className="large-p">view availability</u>

                <ArrowTop
                  className="icon"
                  style={{ margin: "-10px 0 0 10px" }}
                />
              </a>
            </Col>
          </Row>
          <Row className="no-gutters pt-5 mt-5 mb-5">
            <Col md="5">
              <h3>Contact form</h3>
            </Col>
            <Col md="7" className="long">
              <ContactFormNew />
            </Col>
          </Row>
        </Container>
      </Row>
      <CTA />
      <WipeScreen />
    </>
  )
}

Contact.Layout = Layout
export default Contact
