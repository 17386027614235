import React from "react"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"

import useForm from "./../../hooks/useForm"

import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import Check from "../../images/icons/check.svg"
import ArrowTop from "../../images/icons/arrow-top.svg"

// refactor with react-hook-form

const ContactFormNew: React.FC = () => {
  // from tutorial: https://dev.to/karan316/build-forms-using-react-the-easy-way-with-typescript-46bh
  const initialState = {
    email: "",
    fullname: "",
    message: "",
    dataprocessingconsent: false,
  }

  const isEmail = (email: string) => {
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const formSubmitCallback = async () => {
    // submit form
    if (callbackValues.dataprocessingconsent == "on") {
      if (
        callbackValues.fullname.length > 0 &&
        isEmail(callbackValues.email) &&
        callbackValues.message.length > 0
      ) {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "contact-form",
            ...callbackValues,
          }),
        })
          .then(() => navigate("/thanks/"))
          .catch((error) => alert(error))
      }
    } else {
      console.log("missing consent")
    }
  }

  const { handleChange, handleSubmit, callbackValues } = useForm(
    formSubmitCallback,
    initialState
  )

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="contact-form" value="contact" />
      <FormGroup>
        <Input
          id="userEmail"
          name="email"
          placeholder="email"
          type="email"
          required
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Input
          id="fullName"
          name="fullname"
          placeholder="name"
          type="text"
          required
          onChange={handleChange}
        />
      </FormGroup>
      {/* <FormGroup>
        <Input id="exampleSelect" name="select" type="select">
          <option>$10.000 - $50.000</option>
          <option>$50.000 - $100.000</option>
          <option>+$100.000</option>
        </Input>
      </FormGroup> */}
      <FormGroup>
        <Input
          id="userMessage"
          name="message"
          type="textarea"
          placeholder="message"
          className="textarea"
          required
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup className="mt-4">
        <Label check>
          <Input
            type="checkbox"
            name="dataprocessingconsent"
            required
            onChange={handleChange}
          />{" "}
          <span className="cr">
            <Check className="cr-icon" />
          </span>
          <span>
            By submitting this form I agree to processing my personal data
            according to{" "}
            <Link to="/privacy-policy" className="read-more-small">
              privacy policy
              <ArrowTop className="icon" />
            </Link>
          </span>
        </Label>
      </FormGroup>
      <br />
      <Button className="btn-cooler" type="submit">
        Send
      </Button>
    </Form>
  )
}

export default ContactFormNew
