import React, { useState } from "react"

type FormValues = {
  [key: string]: any
}

// useForm functional componen
const useForm = (callback: any, initialState = {}) => {
  const [formValues, setFormValues] = useState(initialState)

  // onChange
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  // onSubmit
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await callback() // triggering the callback
  }

  const values: FormValues = {
    formValues,
  }
  const callbackValues = values.formValues

  // return values
  return {
    handleChange,
    handleSubmit,
    callbackValues,
  }
}

export default useForm
